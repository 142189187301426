import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import parseISO from 'date-fns/parseISO';
import { ReactNode, useMemo } from 'react';
import { Pressable } from 'react-native';

import { Button } from '@oui/app-core/src/components/Button';
import { Icon } from '@oui/app-core/src/components/Icon';
import { chartInset, LineChart } from '@oui/app-core/src/components/LineChart/LineChart';
import { Heading, Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { XAxis } from '@oui/app-core/src/svgCharts';
import { formatDate } from '@oui/lib/src/formatDate';
import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { GQLDateTime } from '@oui/lib/src/types/scalars';

import { RootStackParamList } from '@src/types/navigation';

interface ChartDataPoint {
  date: string;
  value: number;
}

interface ChartData {
  binges: ChartDataPoint[];
  weight: ChartDataPoint[];
  fadFixes: ChartDataPoint[];
}

export const MyProgressQuery = graphql(`
  query MyProgress {
    user {
      ID
      role {
        ID
        eatingProgressEntries {
          practiceID
          eatingProgressEntry {
            binges
            fadFixes
            timestamp
            weight
            weightUnit
          }
        }
      }
    }
  }
`);

const ChartCard = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useTheme();
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: theme.color.accentTwo300,
        paddingHorizontal: 11,
        paddingVertical: 15,
        borderRadius: 20,
        backgroundColor: 'white',
      }}
    >
      {children}
    </View>
  );
};

const Row = (props: {
  onPress: () => void;
  date: GQLDateTime | null;
  children: ReactNode;
  accessibilityLabel: string;
  isLastInDay: boolean;
}) => {
  const { theme } = useTheme();
  return (
    <Pressable
      onPress={props.onPress}
      accessibilityLabel={props.accessibilityLabel}
      style={({ pressed }) => ({ opacity: pressed ? 0.5 : undefined })}
    >
      <View
        row
        style={{
          alignItems: 'flex-start',
          gap: 10,
          borderBottomColor: theme.color.gray600,
          borderBottomWidth: props.isLastInDay ? 1 : 0,
          paddingVertical: 10,
        }}
      >
        <Label
          style={{
            flex: 1,
          }}
          text={
            props.date ? formatDate({ originalDate: props.date, format: 'dayOfWeekMonthDay' }) : ''
          }
        />
        <View
          style={{
            flex: 2,
          }}
        >
          {props.children}
        </View>
      </View>
    </Pressable>
  );
};

export const MyProgress = ({ inRoundedSection }: { inRoundedSection?: boolean }) => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { data } = useQuery(MyProgressQuery);

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const CustomXAxis = ({ data }: { data: ChartDataPoint[] }) => {
    return (
      <XAxis
        style={{
          height: 30,
          marginLeft: 10,
          paddingTop: 7,
        }}
        data={data}
        xAccessor={({ index }) => index}
        formatLabel={(_, index) => {
          const date = new Date(data[index].date);
          return `${date.getMonth() + 1}/${date.getDate()}`;
        }}
        contentInset={{ left: chartInset.left, right: chartInset.right }}
        svg={{
          fontSize: 13,
          fill: '#242226',
          fontFamily: 'OpenSansRegular',
        }}
      />
    );
  };

  const chartData = useMemo(() => {
    const values: ChartData = { binges: [], weight: [], fadFixes: [] };

    if (!data?.user?.role?.eatingProgressEntries) return values;

    const accumulatedEntries = data.user.role.eatingProgressEntries.reduce(
      (acc, entry) => {
        const date = getGQLDate(parseISO(entry.eatingProgressEntry.timestamp));
        const { binges, fadFixes, weight } = entry.eatingProgressEntry;

        if (!acc[date]) acc[date] = { binges: 0, fadFixes: 0, weight: null };

        if (binges !== null) acc[date].binges += binges;
        if (fadFixes !== null) acc[date].fadFixes += fadFixes;
        if (weight !== null) acc[date].weight = weight;

        return acc;
      },
      {} as Record<string, { binges: number; fadFixes: number; weight: number | null }>,
    );

    Object.entries(accumulatedEntries).forEach(([date, entry]) => {
      if (entry.binges > 0) values.binges.push({ value: Math.round(entry.binges), date });
      if (entry.weight !== null) values.weight.push({ value: entry.weight, date });
      if (entry.fadFixes > 0) values.fadFixes.push({ value: Math.round(entry.fadFixes), date });
    });

    return values;
  }, [data]);

  const groupedEntries = useMemo(() => {
    const entries = data?.user?.role?.eatingProgressEntries;
    if (!entries) {
      return [];
    }

    const grouped = entries.reduce(
      (acc, entry) => {
        const date = getGQLDate(parseISO(entry.eatingProgressEntry.timestamp));
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(entry);
        return acc;
      },
      {} as Record<string, typeof entries>,
    );

    return Object.entries(grouped)
      .sort(([dateA], [dateB]) => dateB.localeCompare(dateA))
      .flatMap(([_date, dateEntries]) =>
        dateEntries
          .sort((a, b) =>
            b.eatingProgressEntry.timestamp.localeCompare(a.eatingProgressEntry.timestamp),
          )
          .map((entry, index) => ({
            ...entry,
            showDate: index === 0 ? entry.eatingProgressEntry.timestamp : null,
            isLastInDay: index === dateEntries.length - 1,
          })),
      );
  }, [data?.user?.role?.eatingProgressEntries]);

  const hasChartData =
    chartData.binges.length >= 2 || chartData.fadFixes.length >= 2 || chartData.weight.length >= 2;

  return (
    <View
      style={[
        {
          gap: 25,
        },
        inRoundedSection
          ? null
          : {
              borderRadius: 20,
              backgroundColor: 'white',
              paddingVertical: 15,
              paddingHorizontal: 20,
            },
      ]}
    >
      {inRoundedSection ? null : <Heading level={2} text="My progress" />}

      {hasChartData ? (
        <View
          style={{
            gap: 15,
            marginHorizontal: -20,
            paddingHorizontal: 20,
            paddingTop: 20,
            backgroundColor: 'rgba(200, 210, 248, 0.2)',
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            paddingBottom: 40,
          }}
        >
          {chartData.binges.length >= 2 && (
            <ChartCard>
              <LineChart
                lineColor={theme.color.accentTwo100}
                data={chartData.binges}
                title="Binges per week"
                height={133}
                xAxis={<CustomXAxis data={chartData.binges} />}
              />
            </ChartCard>
          )}

          {chartData.weight.length >= 2 && (
            <ChartCard>
              <LineChart
                lineColor={theme.color.dark}
                data={chartData.weight}
                title="Weight (lbs)"
                height={133}
                xAxis={<CustomXAxis data={chartData.weight} />}
              />
            </ChartCard>
          )}

          {chartData.fadFixes.length >= 2 && (
            <ChartCard>
              <LineChart
                lineColor={theme.color.primary200}
                data={chartData.fadFixes}
                title="Fad fixes per week"
                height={133}
                xAxis={<CustomXAxis data={chartData.fadFixes} />}
              />
            </ChartCard>
          )}
        </View>
      ) : null}

      <View
        style={{
          gap: 15,
          marginTop: hasChartData ? -40 : -20,
          marginHorizontal: -20,
          paddingHorizontal: 20,
          paddingTop: 20,
          backgroundColor: 'white',
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
        }}
      >
        <View
          row
          style={{
            gap: 25,
            justifyContent: 'center',
          }}
        >
          <Button
            text="Weight"
            onPress={() => {
              navigation.navigate('RecordProgress', {
                progressType: 'weight',
              });
            }}
            variant="text"
            icon="plus"
            style={{
              paddingTop: 0,
            }}
          />
          <Button
            text="Binges"
            onPress={() => {
              navigation.navigate('RecordProgress', {
                progressType: 'binges',
              });
            }}
            variant="text"
            icon="plus"
            style={{
              paddingTop: 0,
            }}
          />
          <Button
            text="Fad fixes"
            onPress={() => {
              navigation.navigate('RecordProgress', {
                progressType: 'fadFixes',
              });
            }}
            variant="text"
            icon="plus"
            style={{
              paddingTop: 0,
            }}
          />
        </View>

        <View
          row
          style={{
            borderBottomColor: theme.color.gray600,
            borderBottomWidth: 1,
            paddingBottom: 10,
            gap: 10,
          }}
        >
          <Text
            style={{
              flex: 1,
            }}
            text="Date"
            size={15}
            color={theme.color.gray300}
            weight="semibold"
          />

          <Text
            style={{
              flex: 2,
            }}
            text="Entry"
            size={15}
            color={theme.color.gray300}
            weight="semibold"
          />
        </View>

        <View>
          {groupedEntries.map((entry) => {
            return (
              <Row
                key={entry.practiceID}
                onPress={() => {
                  navigation.navigate('RecordProgress', {
                    practiceID: entry.practiceID,
                  });
                }}
                accessibilityLabel=""
                date={entry.showDate}
                isLastInDay={entry.isLastInDay}
              >
                <View
                  row
                  style={{
                    flex: 1,
                    gap: 10,
                    alignItems: 'flex-start',
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      gap: 10,
                    }}
                  >
                    {entry.eatingProgressEntry.binges ? (
                      <Text
                        text={$t(
                          {
                            id: 'MyProgress_totalBingesPerWeek',
                            defaultMessage: 'Binges per week: {total}',
                          },
                          {
                            total: entry.eatingProgressEntry.binges,
                          },
                        )}
                      />
                    ) : null}

                    {entry.eatingProgressEntry.weight ? (
                      <Text
                        text={$t(
                          {
                            id: 'MyProgress_totalWeight',
                            defaultMessage: 'Weight: {total} {units}',
                          },
                          {
                            total: entry.eatingProgressEntry.weight,
                            units: entry.eatingProgressEntry.weightUnit?.toLocaleLowerCase(),
                          },
                        )}
                      />
                    ) : null}

                    {entry.eatingProgressEntry.fadFixes ? (
                      <Text
                        text={$t(
                          {
                            id: 'MyProgress_totalFadFixesPerWeek',
                            defaultMessage: 'Fad fixes per week: {total}',
                          },
                          {
                            total: entry.eatingProgressEntry.fadFixes,
                          },
                        )}
                      />
                    ) : null}
                  </View>

                  <Icon name="edit" color={theme.color.gray500} size={20} />
                </View>
              </Row>
            );
          })}
        </View>
      </View>
    </View>
  );
};
